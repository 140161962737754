import { useMemo } from 'react';
import { useLatest } from 'react-use';

type Ref<Value> = React.RefCallback<Value> | React.MutableRefObject<Value> | React.ForwardedRef<Value> | undefined;

const setRef = <Value>(ref: Ref<Value>, value: Value) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
};

export const useMergeRefs = <Value>(...refs: Ref<Value>[]): React.RefCallback<Value> => {
  const latestRefs = useLatest(refs);

  return useMemo<React.RefCallback<Value>>(
    () => (value: Value) => {
      const refs = latestRefs.current;

      if (refs.length === 0) {
        return;
      }

      if (refs.length === 2) {
        setRef(latestRefs.current[0], value);
        setRef(latestRefs.current[1], value);
        return;
      }

      refs.forEach(ref => {
        setRef(ref, value);
      });
    },
    [latestRefs],
  );
};
