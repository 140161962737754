import { joinPath } from '../lib';

const ROOT = '/settings';

const DISTRIBUTORS = joinPath(ROOT, 'distributors');
const EXPIRY_DATES = joinPath(ROOT, 'expiry-dates');
const USERS = joinPath(ROOT, 'users');

export const SettingsPaths = {
  ROOT,

  DISTRIBUTORS,
  EXPIRY_DATES,
  USERS,
} as const;
