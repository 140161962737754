import { useCallback } from 'react';
import { differenceInDays, startOfToday } from 'date-fns';
import { flatMap, isEmpty, orderBy, pick, pickBy, size } from 'lodash';
import { createSelector } from 'reselect';

import type { CheckoutCloudCart, CloudCartDistributor } from '@/api/domains/cloud-carts.types';
import type { DrugExpiryDateSettings } from '@/api/domains/settings.types';

import { useCurrentCheckoutCloudCart } from './checkout-cloud-carts';
import { composeCloudCartSummery } from './cloud-carts.helpers';
import type {
  CheckoutDistributorEntries,
  CheckoutDistributorEntry,
  CloudCartEntries,
  CloudCartEntry,
  CloudCartEntryCounts,
  CloudCartSummery,
  TransformedCheckoutCloudCart,
} from './cloud-carts.types';

// ----------------------------------------------------------------------

type SelectorOptionsBase = {
  distributorId: CloudCartDistributor['id'];
  offerId: CloudCartEntry['offer_id'];
};

const selectRawCloudCart = (checkoutCloudCart: TransformedCheckoutCloudCart): CheckoutCloudCart =>
  checkoutCloudCart.rawCheckoutCloudCart;

const selectDistributorEntries = (
  checkoutCloudCart: TransformedCheckoutCloudCart,
  distributorIds?: Array<CloudCartDistributor['id']>,
): CheckoutDistributorEntries =>
  !distributorIds
    ? checkoutCloudCart.checkoutDistributorEntries
    : pick(checkoutCloudCart.checkoutDistributorEntries, distributorIds);

const selectDistributorEntry = (
  checkoutCloudCart: TransformedCheckoutCloudCart,
  distributorId: CloudCartDistributor['id'],
): CheckoutDistributorEntry | undefined => checkoutCloudCart.checkoutDistributorEntries[distributorId];

const selectEntries = (
  checkoutCloudCart: TransformedCheckoutCloudCart,
  distributorId: CloudCartDistributor['id'],
): CloudCartEntries | undefined => selectDistributorEntry(checkoutCloudCart, distributorId)?.entries;

const selectEntry = (
  checkoutCloudCart: TransformedCheckoutCloudCart,
  { distributorId, offerId }: SelectorOptionsBase,
): CloudCartEntry | undefined => selectEntries(checkoutCloudCart, distributorId)?.[offerId];

const selectEntryCounts = (
  checkoutCloudCart: TransformedCheckoutCloudCart,
  { distributorId, offerId }: SelectorOptionsBase,
): CloudCartEntryCounts | undefined =>
  selectDistributorEntry(checkoutCloudCart, distributorId)?.entriesPackagesCounts[offerId];

// ----------------------------------------------------------------------

const selectDistributorEntriesOptimized = createSelector([selectDistributorEntries], entries => entries, {
  memoizeOptions: {
    resultEqualityCheck: (previousResult, nextResult) => size(previousResult) === size(nextResult),
  },
  devModeChecks: { identityFunctionCheck: 'never' },
});

const selectDistributorEntryOptimized = createSelector(
  [
    (checkoutCloudCart: TransformedCheckoutCloudCart, distributorId: CloudCartDistributor['id']) =>
      Boolean(selectDistributorEntry(checkoutCloudCart, distributorId)),
    (checkoutCloudCart: TransformedCheckoutCloudCart, distributorId: CloudCartDistributor['id']) =>
      selectDistributorEntry(checkoutCloudCart, distributorId)?.distributor.name,
    (checkoutCloudCart: TransformedCheckoutCloudCart, distributorId: CloudCartDistributor['id']) =>
      selectDistributorEntry(checkoutCloudCart, distributorId)?.distributor.city,
  ],
  (hasDistributorEntry, name, cityName) => {
    if (!hasDistributorEntry) {
      return;
    }

    return { name, cityName };
  },
);

export const selectDistributorEntrySummery = createSelector([selectDistributorEntry], distributorEntry => {
  if (!distributorEntry) {
    return;
  }

  const { rawCheckoutCloudCartItem } = distributorEntry;

  return {
    ...composeCloudCartSummery(rawCheckoutCloudCartItem),
    minOrderAmount: rawCheckoutCloudCartItem.min_order_amount,
    minOrderAmountWithDelivery: rawCheckoutCloudCartItem.min_order_amount_with_delivery,
  };
});

const selectDistributorEntriesSummary = createSelector([selectDistributorEntries], distributorEntries => {
  return Object.values(distributorEntries).reduce<
    CloudCartSummery & { minOrderAmount: number; minOrderAmountWithDelivery: number }
  >(
    (acc, distributorEntry) => {
      if (distributorEntry) {
        const { rawCheckoutCloudCartItem } = distributorEntry;
        if (rawCheckoutCloudCartItem) {
          const itemSummary = composeCloudCartSummery(rawCheckoutCloudCartItem);

          // Обновляем общие суммы, добавляем новые суммы из itemSummary
          acc.minOrderAmount += rawCheckoutCloudCartItem.min_order_amount || 0;
          acc.minOrderAmountWithDelivery += rawCheckoutCloudCartItem.min_order_amount_with_delivery || 0;

          // Разворачиваем остальные суммы из itemSummary и суммируем их
          Object.entries(itemSummary).forEach(([key, value]) => {
            acc[key as keyof CloudCartSummery] += value;
          });
        }
      }
      return acc;
    },
    {
      totalPrice: 0,
      totalPriceWithVat: 0,
      totalPrepayment: 0,
      totalPrepaymentWithVat: 0,
      totalInstallmentPayment: 0,
      totalInstallmentPaymentWithVat: 0,
      totalDiscount: 0,
      totalDiscountWithVat: 0,
      entriesCount: 0,
      totalPackagesCount: 0,
      minOrderAmount: 0,
      minOrderAmountWithDelivery: 0,
    },
  );
});

const selectIsCloudCartEmpty = createSelector([selectDistributorEntriesOptimized], isEmpty);

const selectCloudCartSummery = createSelector([selectRawCloudCart], composeCloudCartSummery);

const selectSortedDistributorEntries = createSelector([selectDistributorEntriesOptimized], distributorEntries =>
  orderBy(distributorEntries, entry => entry.distributor.name),
);

const selectSortedDistributorEntriesForSelection = createSelector([selectDistributorEntries], distributorEntries =>
  orderBy(distributorEntries, entry => entry.distributor.name),
);

// TODO: Implement memoization
// const selectEntriesOptimized = createSelector(
//   [selectEntries],
//   cloudCartEntries => cloudCartEntries,
//   {
//     memoizeOptions: {
//       resultEqualityCheck: (previousResult, nextResult) => size(previousResult) === size(nextResult),
//     },
//     devModeChecks: { identityFunctionCheck: 'never' },
//   },
// );

const selectSortedEntries = createSelector([selectEntries], cloudCartEntries =>
  orderBy(cloudCartEntries, cloudCartEntry => cloudCartEntry.drug?.name),
);

const selectRawEntries = createSelector([selectRawCloudCart], (rawCheckoutCloudCart): CloudCartEntry[] =>
  flatMap(rawCheckoutCloudCart.items, cloudCartItem => cloudCartItem.items),
);

const selectExpiringEntries = createSelector(
  [selectRawEntries, (_, expirySettings: DrugExpiryDateSettings | undefined) => expirySettings],
  (entries, expirySettings) => {
    if (!expirySettings) {
      return [];
    }

    const { danger, warning } = expirySettings;

    const expiringEntriesDictionary = pickBy(entries, entry => {
      const expiryDate = entry.offer?.offer?.date_expire;

      if (!expiryDate) {
        return false;
      }

      const daysBeforeExpiration = differenceInDays(new Date(expiryDate), startOfToday());

      return (
        daysBeforeExpiration < 0 ||
        (danger && danger.is_active && daysBeforeExpiration <= danger.days) ||
        (warning && warning.is_active && daysBeforeExpiration <= warning.days)
      );
    });

    return orderBy(expiringEntriesDictionary, entry => entry.drug?.name);
  },
);

const selectEntriesWithZeroCount = createSelector([selectRawEntries], entries => {
  const entriesWidthZeroCount = pickBy(entries, entry => entry.quantity === 0);

  return orderBy(entriesWidthZeroCount, entry => entry.drug?.name);
});

const selectEntryOptimized = createSelector(
  [
    (cloudCart: TransformedCheckoutCloudCart, options: SelectorOptionsBase) => selectEntry(cloudCart, options)?.drug,
    (cloudCart: TransformedCheckoutCloudCart, options: SelectorOptionsBase) => selectEntry(cloudCart, options)?.source,
    (cloudCart: TransformedCheckoutCloudCart, options: SelectorOptionsBase) => {
      const entry = selectEntry(cloudCart, options);
      const offerInfo = entry?.offer;
      const cartOfferInfo = entry?.cart_offer;

      if (!entry || (!offerInfo && !cartOfferInfo)) return;

      if (offerInfo) {
        return {
          offer: offerInfo.offer,
          specialOfferBadges: offerInfo.share_badges,
          hasSpecialOffer: !!offerInfo.share && offerInfo.share_badges.length > 0,
        };
      } else if (cartOfferInfo) {
        return {
          offer: cartOfferInfo,
          specialOfferBadges: cartOfferInfo.share_badges,
          hasSpecialOffer: !!cartOfferInfo.share && cartOfferInfo.share_badges.length > 0,
        };
      }
    },
  ],
  (drug, entrySource, offerInfo) => {
    if (!drug || !offerInfo || !entrySource) {
      return;
    }

    return { drug, offerInfo, entrySource };
  },
);

const selectEntrySpecialOffer = createSelector(
  [
    (cloudCart: TransformedCheckoutCloudCart, options: SelectorOptionsBase) =>
      selectEntry(cloudCart, options)?.offer?.share,
  ],
  specialOffer => specialOffer,
);

const selectEntryTotalPriceWithVat = createSelector([selectEntry], entry => entry?.total_price_with_vat ?? 0);

const selectEntryPackagesCount = createSelector([selectEntryCounts], entryCounts => entryCounts?.packagesCount ?? 0);

const selectGiftPackagesCount = createSelector([selectEntryCounts], entryCounts => entryCounts?.giftPackagesCount ?? 0);

// ----------------------------------------------------------------------
const initialEntries: CloudCartEntry[] = [];

export const useCheckoutCloudCartSummery = () =>
  useCurrentCheckoutCloudCart({
    select: selectCloudCartSummery,
  });

export const useIsCheckoutCloudCartEmpty = () =>
  useCurrentCheckoutCloudCart({
    select: selectIsCloudCartEmpty,
  });

const initialCheckoutDistributorEntry: CheckoutDistributorEntry[] = [];

export const useCheckoutDistributorEntries = () => {
  const { data = initialCheckoutDistributorEntry } = useCurrentCheckoutCloudCart({
    select: selectSortedDistributorEntries,
  });

  return data;
};

export const useCheckoutDistributorEntriesForSelection = () => {
  const { data = initialCheckoutDistributorEntry } = useCurrentCheckoutCloudCart({
    select: selectSortedDistributorEntriesForSelection,
  });

  return data;
};

export const useCheckoutDistributorEntry = (distributorId: CloudCartDistributor['id']) =>
  useCurrentCheckoutCloudCart({
    select: useCallback(
      (checkoutCloudCart: TransformedCheckoutCloudCart) =>
        selectDistributorEntryOptimized(checkoutCloudCart, distributorId),
      [distributorId],
    ),
  });

export const useCheckoutDistributorEntriesSummary = (distributorIds: Array<CloudCartDistributor['id']>) => {
  return useCurrentCheckoutCloudCart({
    select: useCallback(
      (checkoutCloudCart: TransformedCheckoutCloudCart) =>
        selectDistributorEntriesSummary(checkoutCloudCart, distributorIds),
      [distributorIds],
    ),
  });
};

export const useCheckoutDistributorEntrySummary = (distributorId: CloudCartDistributor['id']) =>
  useCurrentCheckoutCloudCart({
    select: useCallback(
      (checkoutCloudCart: TransformedCheckoutCloudCart) =>
        selectDistributorEntrySummery(checkoutCloudCart, distributorId),
      [distributorId],
    ),
  });

export const useCheckoutCloudCartEntriesByDistributorId = (distributorId: CloudCartDistributor['id']) => {
  const { data = initialEntries } = useCurrentCheckoutCloudCart({
    select: useCallback(
      (checkoutCloudCart: TransformedCheckoutCloudCart) => selectSortedEntries(checkoutCloudCart, distributorId),
      [distributorId],
    ),
  });

  return data;
};

export type UseCheckoutCloudCartEntryOptions = SelectorOptionsBase;

export const useCheckoutCloudCartEntry = (options: UseCheckoutCloudCartEntryOptions) =>
  useCurrentCheckoutCloudCart({
    select: useCallback(
      (checkoutCloudCart: TransformedCheckoutCloudCart) => selectEntryOptimized(checkoutCloudCart, options),
      /* eslint-disable-next-line */
      [options.distributorId, options.offerId],
    ),
  });

export type UseCheckoutCloudCartEntrySpecialOfferOptions = SelectorOptionsBase;

export const useCheckoutCloudCartEntrySpecialOffer = (options: UseCheckoutCloudCartEntrySpecialOfferOptions) =>
  useCurrentCheckoutCloudCart({
    select: useCallback(
      (checkoutCloudCart: TransformedCheckoutCloudCart) => selectEntrySpecialOffer(checkoutCloudCart, options),
      /* eslint-disable-next-line */
      [options.distributorId, options.offerId],
    ),
  });

export type UseCheckoutCloudCartEntryTotalPriceWithVatOptions = SelectorOptionsBase;

export const useCheckoutCloudCartEntryTotalPriceWithVat = (
  options: UseCheckoutCloudCartEntryTotalPriceWithVatOptions,
) => {
  const { data = 0 } = useCurrentCheckoutCloudCart({
    select: useCallback(
      (checkoutCloudCart: TransformedCheckoutCloudCart) => selectEntryTotalPriceWithVat(checkoutCloudCart, options),
      /* eslint-disable-next-line */
      [options.distributorId, options.offerId],
    ),
  });

  return data;
};

export type UseHasCheckoutCloudCartEntryOptions = UseCheckoutCloudCartEntryOptions;

export const useHasCheckoutCloudCartEntry = (options: UseHasCheckoutCloudCartEntryOptions) => {
  const { data: cartEntry } = useCheckoutCloudCartEntry(options);

  return !!cartEntry;
};

export type UseCheckoutCloudCartEntryPackagesCountOptions = SelectorOptionsBase;

export const useCheckoutCloudCartEntryPackagesCount = (options: UseCheckoutCloudCartEntryPackagesCountOptions) => {
  const { data = 0 } = useCurrentCheckoutCloudCart({
    select: useCallback(
      (checkoutCloudCart: TransformedCheckoutCloudCart) => selectEntryPackagesCount(checkoutCloudCart, options),
      /* eslint-disable-next-line */
      [options.distributorId, options.offerId],
    ),
  });

  return data;
};

export type UseCheckoutCloudCartGiftPackagesCountOptions = SelectorOptionsBase;

export const useCheckoutCloudCartGiftPackagesCount = (options: UseCheckoutCloudCartGiftPackagesCountOptions) => {
  const { data = 0 } = useCurrentCheckoutCloudCart({
    select: useCallback(
      (checkoutCloudCart: TransformedCheckoutCloudCart) => selectGiftPackagesCount(checkoutCloudCart, options),
      /* eslint-disable-next-line */
      [options.distributorId, options.offerId],
    ),
  });

  return data;
};

export const useCheckoutCloudCartExpiringEntries = (expirySettings: DrugExpiryDateSettings | undefined) => {
  const { data = initialEntries } = useCurrentCheckoutCloudCart({
    select: useCallback(
      (checkoutCloudCart: TransformedCheckoutCloudCart) => selectExpiringEntries(checkoutCloudCart, expirySettings),
      [expirySettings],
    ),
  });

  return data;
};

export const useCheckoutCloudCartEntriesWithZeroCount = () => {
  const { data = initialEntries } = useCurrentCheckoutCloudCart({
    select: selectEntriesWithZeroCount,
  });

  return data;
};
