import { httpClient } from '@/shared/lib/axios';

import type { PaginatedList } from '../types/pagination.types';
import type { Contact, DistributorInfo } from './contractors.types';

export type FetchDistributorInfoParams = {
  distributor_id: number;
};

export type FetchContractorContactsParams = {
  contractor_id: number;
};

export const ContractorsApi = {
  fetchDistributorInfo: async ({ distributor_id }: FetchDistributorInfoParams) => {
    const { data } = await httpClient.get<DistributorInfo>(`distributors/${distributor_id}`);

    return data;
  },

  fetchContractorContacts: async (params: FetchContractorContactsParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Contact>>('contacts', { params });

    return data;
  },
};
