import { useNavigate, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';

import {
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogBodyMutedText,
  ConfirmationDialogContainer,
  ConfirmationDialogHeader,
  type ConfirmationDialogContainerProps,
} from '@/components/dialogs';
import { formatNumberByThousands } from '@/shared/helpers/format.helpers';
import { useSpecification } from '@/shared/hooks/useSpecification';
import { DorimPromoPaths, type SpecificationDetailPathParams } from '@/shared/lib/react-router';

import { useSpecificationContextWindowsVisibility, useSpecificationStoreActions } from '../specification.store';

type CashbackAcceptPaymentDialogProps = Omit<ConfirmationDialogContainerProps, 'subject' | 'open'>;

export const ContractClosingDialog = (props: CashbackAcceptPaymentDialogProps) => {
  const params = useParams<SpecificationDetailPathParams>();
  const { data } = useSpecification({ specificationId: Number(params.specificationId) });
  const { agreement } = data || {};

  const { isContractClosingDialogVisible } = useSpecificationContextWindowsVisibility();
  const { closeContractClosingDialog } = useSpecificationStoreActions();

  const navigate = useNavigate();
  const handleProceed = () => {
    closeContractClosingDialog();
    navigate(DorimPromoPaths.SPECIFICATIONS_ROOT_PATH, { replace: true });
  };

  return (
    <ConfirmationDialogContainer open={isContractClosingDialogVisible} subject="confirm" {...props}>
      <ConfirmationDialogHeader>Закрытие спецификации</ConfirmationDialogHeader>
      {agreement && (
        <ConfirmationDialogBodyMutedText>
          Получение выплаты в размере{' '}
          <Typography component="span" variant="inherit" color="text.primary">
            <Typography component="span" variant="inherit" fontWeight={600}>
              {formatNumberByThousands(agreement.cashback_sum)}
            </Typography>{' '}
            <Typography component="span" variant="inherit">
              UZS
            </Typography>
          </Typography>{' '}
          подтверждено. Спецификация №{agreement.document_number} закрыта и перемещена в архив
        </ConfirmationDialogBodyMutedText>
      )}
      <ConfirmationDialogActions>
        <ConfirmationDialogActionProceedButton autoFocus onClick={handleProceed}>
          Закрыть
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
