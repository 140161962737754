import { Fragment } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { Link, ListItemText, Menu, MenuItem, Stack, SvgIcon, Tab, Tabs } from '@mui/material';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import { ActionButton } from '@/components/buttons';
import { DocumentToolbar, DocumentToolbarButton, type DocumentToolbarButtonProps } from '@/components/DocumentToolbar';
import { CashbackStatus } from '@/components/statuses';
import { DocumentToolbarSkeleton } from '@/pages/dorim-promo/shared';
import { ReactComponent as DownloadSVG } from '@/shared/assets/icons-2.0/download.svg';
import {
  CASHBACK_STATUS_AWAITING_PAYMENT,
  CASHBACK_STATUS_NOT_CONFIRMED,
  type CashbackStatusId,
} from '@/shared/constants/cashback-status.constants';
import { useMainContractDocument } from '@/shared/hooks/useMainContractDocument';
import { useSpecification } from '@/shared/hooks/useSpecification';
import { DorimPromoPaths, useMatchLinks, type SpecificationDetailPathParams } from '@/shared/lib/react-router';

import { useSpecificationIdFromPathParams } from '../shared';
import { useSpecificationStoreActions } from './specification.store';

type SpecificationDocumentButtonProps = {
  recordStatusId: CashbackStatusId;
  isMainContractSigned: boolean;
  specificationId: number;
};

const getDocumentButtonText = (recordStatusId: CashbackStatusId) => {
  switch (recordStatusId) {
    case CASHBACK_STATUS_NOT_CONFIRMED:
      return 'Прочитать';
    default:
      return 'Спецификация';
  }
};

const getDocumentButtonVariant = (recordStatusId: CashbackStatusId): DocumentToolbarButtonProps['variant'] => {
  switch (recordStatusId) {
    case CASHBACK_STATUS_NOT_CONFIRMED:
      return 'contained';
    default:
      return 'outlined';
  }
};

const DownloadTemplatesButton = () => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'SpecificationDocumentToolbar' });

  return (
    <Fragment>
      <ActionButton
        startIcon={<SvgIcon component={DownloadSVG} inheritViewBox fontSize="small" />}
        endIcon={<ArrowDropDownIcon />}
        {...bindTrigger(popupState)}
      >
        Шаблоны документов
      </ActionButton>

      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: -4, horizontal: 'center' }}
        slotProps={{
          paper: {
            sx: { width: 233 },
          },
        }}
        {...bindMenu(popupState)}
      >
        <MenuItem
          component={Link}
          href={import.meta.env.VITE_APP_SPECIFICATION_WORK_COMPLETION_CERT_URL}
          onClick={popupState.close}
        >
          <ListItemText primaryTypographyProps={{ variant: 'Body/medium' }}>Акт выполненных работ</ListItemText>
        </MenuItem>
        <MenuItem component={Link} href={import.meta.env.VITE_APP_SPECIFICATION_INVOICE_URL} onClick={popupState.close}>
          <ListItemText primaryTypographyProps={{ variant: 'Body/medium' }}>Счёт-фактура</ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

const SpecificationDocumentButton = (props: SpecificationDocumentButtonProps) => {
  const { recordStatusId, isMainContractSigned, specificationId } = props;
  const { openAcceptContractConfirmationDialog } = useSpecificationStoreActions();

  const isContractConfirmed = recordStatusId !== CASHBACK_STATUS_NOT_CONFIRMED;

  return (
    <Fragment>
      {!isContractConfirmed && (
        <DocumentToolbarButton variant="contained" onClick={openAcceptContractConfirmationDialog}>
          Подтвердить спецификацию
        </DocumentToolbarButton>
      )}
      {isContractConfirmed && isMainContractSigned && (
        <DocumentToolbarButton
          component={RouterLink}
          variant={getDocumentButtonVariant(recordStatusId)}
          to={DorimPromoPaths.generateSpecificationDetailContractDocumentPath({
            specificationId: String(specificationId),
          })}
        >
          {getDocumentButtonText(recordStatusId)}
        </DocumentToolbarButton>
      )}
    </Fragment>
  );
};

const ToolbarTabs = () => {
  const specificationId = useSpecificationIdFromPathParams();
  const stringifiedSpecificationId = String(specificationId);

  const procurementPlanPath = DorimPromoPaths.generateSpecificationDetailProcurementPlanPath({
    specificationId: stringifiedSpecificationId,
  });
  const planogramPath = DorimPromoPaths.generateSpecificationDetailPlanogramPath({
    specificationId: stringifiedSpecificationId,
  });
  const [matchedLink] = useMatchLinks(procurementPlanPath, planogramPath);

  if (!specificationId) return null;

  return (
    matchedLink?.pathname && (
      <Tabs value={matchedLink.pathname} sx={{ pl: 1 }} textColor="primary" indicatorColor="primary">
        <Tab component={RouterLink} to={procurementPlanPath} value={procurementPlanPath} replace label="План закупки" />
        <Tab component={RouterLink} to={planogramPath} value={planogramPath} replace label="План выкладки" />
      </Tabs>
    )
  );
};

export const SpecificationDocumentToolbar = () => {
  const {
    query: { isLoading: isLoadingMainContractDocument, data: mainContractDocument },
  } = useMainContractDocument();

  const params = useParams<SpecificationDetailPathParams>();
  const specificationId = Number(params.specificationId);
  const { isLoading: isLoadingSpecification, data: specification } = useSpecification({ specificationId });

  const isLoading = isLoadingMainContractDocument || isLoadingSpecification;

  if (isLoading) {
    return <DocumentToolbarSkeleton />;
  }

  if (!specification?.agreement || !mainContractDocument) {
    return null;
  }

  const shouldRenderTemplateButton = specification.agreement.record_status_id === CASHBACK_STATUS_AWAITING_PAYMENT;

  return (
    <DocumentToolbar
      title={specification.agreement.name}
      documentNumber={specification.agreement.document_number}
      button={
        <Fragment>
          {!shouldRenderTemplateButton && (
            <SpecificationDocumentButton
              isMainContractSigned={mainContractDocument.is_signed}
              specificationId={specificationId}
              recordStatusId={specification.agreement.record_status_id}
            />
          )}
          {shouldRenderTemplateButton && (
            <Stack direction="row" spacing={2} alignItems="center">
              <DownloadTemplatesButton />
              <SpecificationDocumentButton
                isMainContractSigned={mainContractDocument.is_signed}
                specificationId={specificationId}
                recordStatusId={specification.agreement.record_status_id}
              />
            </Stack>
          )}
        </Fragment>
      }
      footer={<ToolbarTabs />}
    >
      <CashbackStatus statusId={specification.agreement.record_status_id} />
    </DocumentToolbar>
  );
};
