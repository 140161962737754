import { joinPath } from '../lib';
import { AuthPaths } from './auth.paths';
import { OrdersPaths } from './router.paths';
import { SettingsPaths } from './settings.paths';

// ----------------------------------------------------------------------
// SIGN IN

export const SignInLinks = {
  root: AuthPaths.SIGN_IN.ROOT,
  ban: AuthPaths.SIGN_IN.BAN,
};

// ----------------------------------------------------------------------
// SETTINGS

export const SettingsLinks = {
  root: SettingsPaths.ROOT,

  distributors: joinPath(SettingsPaths.ROOT, SettingsPaths.DISTRIBUTORS),
  expiryDates: joinPath(SettingsPaths.ROOT, SettingsPaths.EXPIRY_DATES),
  users: joinPath(SettingsPaths.ROOT, SettingsPaths.USERS),
};

// ----------------------------------------------------------------------
// MY ORDERS

export const OrdersLinks = {
  root: OrdersPaths.ROOT,
};

// ----------------------------------------------------------------------
// SIGNUP

export const SignUpLinks = {
  root: AuthPaths.SIGN_UP.ROOT,
  step: AuthPaths.SIGN_UP.STEP,
  ban: AuthPaths.SIGN_UP.BAN,
  final: AuthPaths.SIGN_UP.FINAL,
};
