import { forwardRef, useRef, useState } from 'react';
import { useHoverDirty } from 'react-use';
import { TextField, type TextFieldProps } from '@mui/material';

import { ClearAdornment, type ClearEndAdornmentProps } from './ClearAdornment';

type LimitedClearEndAdornmentProps = Pick<ClearEndAdornmentProps, 'onClear'>;
type LimitedTextFieldProps = Omit<TextFieldProps, 'inputRef' | 'onChange'>;

export type ClearableTextFieldProps = LimitedClearEndAdornmentProps &
  LimitedTextFieldProps & {
    isClearable?: boolean;
    onValueChange?: (value: string) => void;
  };

export const ClearableTextField = forwardRef<HTMLInputElement, ClearableTextFieldProps>((props, forwardedRef) => {
  const {
    isClearable = true,
    value,
    onClear,
    onFocus: passedOnFocus,
    onBlur: passedOnBlur,
    onValueChange,
    ...restOfProps
  } = props;

  const textFieldRef = useRef<HTMLDivElement>(null);
  const isHovering = useHoverDirty(textFieldRef);

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = event => {
    setIsFocused(true);
    passedOnFocus?.(event);
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = event => {
    setIsFocused(false);
    passedOnBlur?.(event);
  };

  const handleClear = () => {
    onClear?.();
    onValueChange?.('');
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    onValueChange?.(event.target.value);
  };

  const isClearButtonVisible = !!value && (isHovering || isFocused);

  return (
    <TextField
      fullWidth
      ref={textFieldRef}
      inputRef={forwardedRef}
      value={value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      InputProps={{
        endAdornment: isClearable && (
          <ClearAdornment isClearButtonVisible={isClearButtonVisible} onClear={handleClear} position="end" />
        ),
        ...restOfProps.InputProps,
      }}
      {...restOfProps}
    />
  );
});

if (import.meta.env.DEV) {
  ClearableTextField.displayName = 'ClearableTextField';
}
