type DimensionsTheme = {
  sidebar: {
    width: number;
  };
  header: {
    height: number;
  };
  summaryBar: {
    height: number;
  };
  modal: {
    maxHeight: number;

    header: {
      height: number;
    };
    tabbar: {
      height: number;
    };
    sidebar: {
      width: number;
    };
    footer: {
      height: number;
    };
    toolbar: {
      height: number;
    };
  };
  dataGrid: {
    toolbar: {
      minHeight: number;
    };
    row: {
      height: number;
    };
    header: {
      height: number;
    };
  };
};

declare module '@mui/material/styles' {
  interface Theme {
    dimensions: DimensionsTheme;
  }

  interface ThemeOptions {
    dimensions?: DimensionsTheme;
  }
}

// ----------------------------------------------------------------------

export const dimensions: DimensionsTheme = {
  sidebar: {
    width: 88,
  },
  header: {
    height: 56,
  },
  summaryBar: {
    height: 56,
  },
  modal: {
    maxHeight: 944,

    header: {
      height: 56,
    },
    tabbar: {
      height: 48,
    },
    sidebar: {
      width: 400,
    },
    footer: {
      height: 68,
    },
    toolbar: {
      height: 56,
    },
  },
  dataGrid: {
    toolbar: {
      minHeight: 48,
    },
    row: {
      height: 56,
    },
    header: {
      height: 40,
    },
  },
};
