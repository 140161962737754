import { Clear } from '@mui/icons-material';
import type { InputAdornmentProps } from '@mui/material';
import { IconButton, InputAdornment } from '@mui/material';

export type ClearEndAdornmentProps = InputAdornmentProps & {
  isClearButtonVisible: boolean;
  onClear?: () => void;
};

export const ClearAdornment = (props: ClearEndAdornmentProps) => {
  const { isClearButtonVisible, onClear, ...restAdornmentProps } = props;

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <InputAdornment {...restAdornmentProps}>
      {isClearButtonVisible && (
        <IconButton size="small" onClick={onClear} onMouseDown={handleMouseDown} data-testId="clear-input-btn">
          <Clear fontSize="small" />
        </IconButton>
      )}
    </InputAdornment>
  );
};
