import { Fragment, useRef, type KeyboardEvent } from 'react';
import { useBoolean } from 'react-use';
import { Popover, Stack } from '@mui/material';

import { useProfile } from '../../queries/useProfile';
import type { ProfileLinks } from '../../types';
import { Avatar } from '../Avatar';
import { ProfilePopover } from './ProfilePopover';
import { UserProfileStyled, userProfileSxStyles } from './styles';
import { UserProfileSkeleton } from './UserProfileSkeleton';

type Props = ProfileLinks;

export const UserProfile = (props: Props) => {
  const { user, fullName, isLoading } = useProfile();
  const [isOpenPopover, triggerOpenPopover] = useBoolean(false);
  const anchorEl = useRef(null);

  const handleAvatarKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Enter') {
      triggerOpenPopover();
    }
  };

  if (isLoading) {
    return <UserProfileSkeleton />;
  }

  return (
    <Fragment>
      <UserProfileStyled
        direction="row"
        onClick={triggerOpenPopover}
        onKeyDown={handleAvatarKeyDown}
        ref={anchorEl}
        role="button"
        tabIndex={1}
        data-testId="header-profile-info"
      >
        <Avatar fullName={fullName} imgLink={user?.avatar_url} size={32} isAura={isOpenPopover} />

        <Stack sx={userProfileSxStyles.userName}>
          <Stack>{user?.first_name}</Stack>
          <Stack>{user?.last_name}</Stack>
        </Stack>
      </UserProfileStyled>

      <Popover
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorEl={anchorEl.current}
        onClose={triggerOpenPopover}
        open={isOpenPopover}
      >
        <ProfilePopover {...props} />
      </Popover>
    </Fragment>
  );
};
