import { Navigate } from 'react-router-dom';

import type { RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';
import { DorimPromoPaths } from '@/shared/lib/react-router';

import { useSpecificationIdFromPathParams } from '../shared';
import { Layout } from './_layout';
import { PlanogramTasksPageRoute } from './planogram';
import { ProcurementPlanPageRoute } from './procurement-plan';

const RedirectToProcurementPlan = () => {
  const specificationId = useSpecificationIdFromPathParams();

  return (
    <Navigate
      to={DorimPromoPaths.generateSpecificationDetailProcurementPlanPath({ specificationId: String(specificationId) })}
      replace
    />
  );
};

export const SpecificationPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_PATH,
  element: <Layout />,
  children: [
    {
      index: true,
      element: <RedirectToProcurementPlan />,
    },
    ProcurementPlanPageRoute,
    PlanogramTasksPageRoute,
  ],
};
