import { Circle as CircleIcon, Close as CloseIcon } from '@mui/icons-material';
import { IconButton, Stack, styled, Typography, type StackProps } from '@mui/material';
import { isArray, isString } from 'lodash';
import type { Merge } from 'type-fest';

import { InputLabel } from '@/components/labels';

type ChunkedTitle = Array<string>;

const StyledRoot = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: theme.shape.divider,

  '> *:not(:first-of-type)': {
    flexShrink: 0,
  },
})) as typeof Stack;

type ComposableTitleProps = {
  chunks: ChunkedTitle;
};

const IMPORTANT_INFO_CHUNKS_LAST_INDEX = 1;

const ComposableTitle = (props: ComposableTitleProps) => {
  const { chunks } = props;

  return (
    <Stack
      direction="row"
      spacing={1}
      divider={<CircleIcon fontSize="small" sx={{ color: 'neutral.500' }} />}
      sx={{ flexGrow: 1, overflow: 'hidden', alignItems: 'center' }}
    >
      {chunks.map((chunk, index) => (
        <Typography
          key={index}
          noWrap
          component="span"
          variant="Title/large"
          sx={{
            color: index === 0 ? 'text.primary' : 'neutral.700',
            flexShrink: index > IMPORTANT_INFO_CHUNKS_LAST_INDEX ? 1 : 0,
          }}
        >
          {chunk}
        </Typography>
      ))}
    </Stack>
  );
};

type ExtendedStackProps = Merge<
  StackProps,
  {
    title: string | ChunkedTitle;
    onClose?: () => void;
    hideCloseButton?: boolean;
    hideHotkey?: boolean;
  }
>;

export type ModalHeaderProps = Omit<ExtendedStackProps, 'children'>;

export const ModalHeader = (props: ModalHeaderProps) => {
  const { title, hideCloseButton, hideHotkey, onClose, ...restOfProps } = props;

  return (
    <StyledRoot direction="row" spacing={1} alignItems="center" {...restOfProps}>
      {isString(title) && (
        <Typography component="p" variant="Title/large" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
      )}
      {isArray(title) && <ComposableTitle chunks={title} />}
      {!hideCloseButton && !hideHotkey && <InputLabel>Esc</InputLabel>}
      {!hideCloseButton && (
        <IconButton sx={{ color: 'neutral.700' }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </StyledRoot>
  );
};
