import { httpClient } from '@/shared/lib/axios';

export type SearchPageReportFormData = {
  error_type_ids: Array<number>;
  error_message: string;
  pharmacy_id: number;
  offer_id: number;
  drug_id: number;
};

export type SendDorimPromoCalculationErrorReportParams = {
  error_type_ids: Array<number>;
  agreement_id: number;
};

export const ReportApi = {
  sendReportFromSearchPage: (formData: SearchPageReportFormData) => httpClient.post<void>('report/report', formData),

  sendDorimPromoCalculationErrorReport: (params: SendDorimPromoCalculationErrorReportParams) =>
    httpClient.post<void>('report/report', params),
};
