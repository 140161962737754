import { useSpecificationContractDocument } from '@/shared/hooks/useSpecificationContractDocument';
import { DorimPromoPaths, RedirectWithoutAccess } from '@/shared/lib/react-router';

import { useSpecificationIdFromPathParams } from '../specifications-root/detail/shared/useSpecificationIdFromPathParams';

type UnsignedSpecificationContractGuardProps = React.PropsWithChildren;

export const UnsignedSpecificationContractGuard = (props: UnsignedSpecificationContractGuardProps) => {
  const { children } = props;

  const specificationId = useSpecificationIdFromPathParams();
  const { isSigned, query } = useSpecificationContractDocument({ specificationId });

  if (query.isLoading) {
    return null;
  }

  const path = specificationId
    ? DorimPromoPaths.generateSpecificationDetailPath({ specificationId: String(specificationId) })
    : DorimPromoPaths.SPECIFICATIONS_ROOT_PATH;

  return (
    <RedirectWithoutAccess hasAccess={!isSigned} to={path}>
      {children}
    </RedirectWithoutAccess>
  );
};
