import { useMemo, useState } from 'react';
import { Collapse, Stack } from '@mui/material';
import { remove, uniqBy } from 'lodash';

import type { DistributorBranch, DistributorInfo } from '@/api/domains/contractors.types';
import type { Region } from '@/api/domains/regions.types';
import { ActionButton } from '@/components/buttons';

import type { Office } from '../DistributorInfoContent';
import { ContactsCard } from './ContactsCard';
import { InfoSection } from './InfoSection';

type DeliveryRegionsProps = {
  branches?: DistributorBranch[];
  distributorInfo?: DistributorInfo;
};

const getDeliveryRegions = (
  branches: DistributorInfo['branches'] | undefined,
  mainOfficeDeliveryRegions: DistributorInfo['delivery_regions'],
) => {
  let branchesDeliveryRegions: Region[] = [];

  if (branches) {
    branchesDeliveryRegions = branches.flatMap(branch => branch.delivery_regions || []);
  }

  const allRegions = [...(mainOfficeDeliveryRegions ?? []), ...branchesDeliveryRegions];
  const uniqueRegionsMap = uniqBy(allRegions, 'id');

  return uniqueRegionsMap;
};

const getOfficesByRegion = (branches?: DistributorInfo['branches'], distributorInfo?: DistributorInfo) => {
  const allOffices: (DistributorBranch | DistributorInfo)[] = branches ? [...branches] : [];

  if (distributorInfo) {
    allOffices.push(distributorInfo);
  }

  const regionOfficesMap: Record<number, Office[]> = {};

  allOffices.forEach(office => {
    office.delivery_regions?.forEach(region => {
      regionOfficesMap[region.id] = regionOfficesMap[region.id] || [];

      const isMainOffice = 'branches' in office;
      const currentOffices = regionOfficesMap[region.id];

      if (currentOffices.length === 0) {
        currentOffices.push(office);
      } else {
        if (isMainOffice) return;
        // Если регион доставки главного офиса и филиала пересекаются, то оставляем только филиал
        remove(currentOffices, office => 'branches' in office);

        currentOffices.push(office);
      }
    });
  });

  return regionOfficesMap;
};

export const DeliveryRegions = (props: DeliveryRegionsProps) => {
  const { branches, distributorInfo } = props;
  const { delivery_regions: mainOfficeDeliveryRegions = [] } = distributorInfo || {};

  const [selectedOffices, setSelectedOffices] = useState<Office[] | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<Region | null>(null);

  const deliveryRegions: Region[] = useMemo(
    () => getDeliveryRegions(branches, mainOfficeDeliveryRegions),
    [branches, mainOfficeDeliveryRegions],
  );

  const officesByRegion = useMemo(() => getOfficesByRegion(branches, distributorInfo), [branches, distributorInfo]);

  const selectRegion = (region: Region) => {
    setSelectedRegion(region);
    setSelectedOffices(officesByRegion[region.id]);

    if (region === selectedRegion) {
      setSelectedOffices(null);
      setSelectedRegion(null);
    }
  };

  if (deliveryRegions.length === 0) return null;

  return (
    <InfoSection title="Регионы доставки и филиалы">
      <Stack spacing={1} direction="row" flexWrap="wrap" rowGap={1}>
        {deliveryRegions.map(region => (
          <ActionButton
            key={region.id}
            isSelected={selectedRegion?.id === region.id}
            onClick={() => selectRegion(region)}
          >
            {region.name}
          </ActionButton>
        ))}
      </Stack>

      <Collapse in={!!selectedOffices}>
        <Stack spacing={2}>{selectedOffices?.map(office => <ContactsCard office={office} key={office.id} />)}</Stack>
      </Collapse>
    </InfoSection>
  );
};
