import { createRoot } from 'react-dom/client';

import { applyGlobalConfigDateFns } from '@/shared/lib/date-fns';
import { applyGlobalConfigI18next } from '@/shared/lib/i18next';

import { Router } from './Router';

applyGlobalConfigDateFns();
applyGlobalConfigI18next();

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);
root.render(<Router />);
