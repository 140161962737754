import { useUnmount } from 'react-use';
import { omit } from 'lodash';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { CLOUD_CART_ENTRY_SOURCE_AUTO_SELECTION, type CloudCartEntrySource } from '@/api/domains/cloud-carts.constants';
import type { DrugInfo } from '@/api/domains/drugs.types';

type State = {
  isActionHappened: boolean;
  isEnableGlobalInput: boolean;
  isSelectingDrug: boolean;

  searchQuery: string;
  alphabeticalIndexSearchQuery: string;
  selectedDrugId: number | null;
  selectedOfferId: number | null;
  selectedAnalogDrug: DrugInfo | null;
  exactDrugSearchId: number | null;
  isExactDrugSearchIdFromAutoSelection: boolean;

  isUsersFeedbackDialogVisible: boolean;
};

type Actions = {
  setIsEnableGlobalInput: (isEnableGlobalInput: boolean) => void;
  enableGlobalInput: () => void;
  disableGlobalInput: () => void;

  setIsActionHappened: (isActionHappened: boolean) => void;
  setIsSelectingDrug: (isSelectingDrug: boolean) => void;

  setSelectedAnalogDrug: (selectedAnalogDrug: DrugInfo | null) => void;
  clearSelectedAnalogDrug: () => void;

  setSearchQuery: (searchQuery: string) => void;
  clearSearchQuery: () => void;

  setAlphabeticalIndexSearchQuery: (alphabeticalIndexSearchQuery: string) => void;
  clearAlphabeticalIndexSearchQuery: () => void;

  setSelectedDrugId: (selectedDrugId: number | null) => void;
  setSelectedOfferId: (selectedOfferId: number | null) => void;

  setExactDrugSearchId: (params: { drugId: number | null; entrySource?: CloudCartEntrySource }) => void;
  resetExactDrugSearchId: () => void;

  setIsUsersFeedbackDialogVisible: (isUsersFeedbackDialogVisible: boolean) => void;
};

export type ProductSelectionStore = State & {
  actions: Actions;
};

const defaultState: State = {
  isActionHappened: false,
  isEnableGlobalInput: true,
  isSelectingDrug: false,
  isExactDrugSearchIdFromAutoSelection: false,

  searchQuery: '',
  alphabeticalIndexSearchQuery: '',

  selectedDrugId: null,
  selectedOfferId: null,
  selectedAnalogDrug: null,
  exactDrugSearchId: null,

  // dialogs
  isUsersFeedbackDialogVisible: false,
};

export const useProductSelectionStore = createWithEqualityFn<ProductSelectionStore>()(
  devtools(
    (set, get) => ({
      ...defaultState,

      // actions
      actions: {
        setIsEnableGlobalInput: isEnableGlobalInput => set({ isEnableGlobalInput }, false, 'setIsEnableGlobalInput'),
        enableGlobalInput: () => set({ isEnableGlobalInput: true }, false, 'enableGlobalInput'),
        disableGlobalInput: () => set({ isEnableGlobalInput: false }, false, 'disableGlobalInput'),

        setIsActionHappened: isActionHappened => set({ isActionHappened }, false, 'setIsActionHappened'),
        setIsSelectingDrug: isSelectingDrug => set({ isSelectingDrug }, false, 'setIsSelectingDrug'),

        setSelectedAnalogDrug: selectedAnalogDrug => {
          get().actions.resetExactDrugSearchId();
          set({ selectedAnalogDrug }, false, 'setSelectedAnalogDrug');
        },
        clearSelectedAnalogDrug: () => set({ selectedAnalogDrug: null }, false, 'clearSelectedAnalogDrug'),

        setSearchQuery: searchQuery => {
          if (searchQuery) {
            get().actions.resetExactDrugSearchId();
          }

          set({ searchQuery }, false, 'setSearchQuery');
        },
        clearSearchQuery: () => set({ searchQuery: '' }, false, 'clearSearchQuery'),

        setAlphabeticalIndexSearchQuery: alphabeticalIndexSearchQuery => {
          get().actions.clearSearchQuery();
          get().actions.resetExactDrugSearchId();
          set({ alphabeticalIndexSearchQuery }, false, 'setAlphabeticalIndexSearchQuery');
        },
        clearAlphabeticalIndexSearchQuery: () =>
          set({ alphabeticalIndexSearchQuery: '' }, false, 'clearAlphabeticalIndexSearchQuery'),

        setSelectedDrugId: selectedDrugId => set({ selectedDrugId }, false, 'setSelectedDrugId'),
        setSelectedOfferId: selectedOfferId => set({ selectedOfferId }, false, 'setSelectedOfferId'),

        setExactDrugSearchId: ({ drugId, entrySource }) =>
          set(
            {
              exactDrugSearchId: drugId,
              isExactDrugSearchIdFromAutoSelection: entrySource === CLOUD_CART_ENTRY_SOURCE_AUTO_SELECTION,
            },
            false,
            'setExactDrugSearchId',
          ),
        resetExactDrugSearchId: () =>
          set(
            {
              exactDrugSearchId: null,
              isExactDrugSearchIdFromAutoSelection: false,
            },
            false,
            'clearExactDrugSearchId',
          ),

        setIsUsersFeedbackDialogVisible: isUsersFeedbackDialogVisible =>
          set({ isUsersFeedbackDialogVisible }, false, 'setIsUsersFeedbackDialogVisible'),
      },
    }),
    {
      name: 'product-selection-store',
      enabled: import.meta.env.DEV,
    },
  ),
);

export const selectIsActionHappened = (state: ProductSelectionStore) => state.isActionHappened;
export const selectIsEnableGlobalInput = (state: ProductSelectionStore) => state.isEnableGlobalInput;

export const selectSearchQuery = (state: ProductSelectionStore) => state.searchQuery;
export const selectAlphabeticalIndexSearchQuery = (state: ProductSelectionStore) => state.alphabeticalIndexSearchQuery;
export const selectSelectedDrugId = (state: ProductSelectionStore) => state.selectedDrugId;
export const selectSelectedOfferId = (state: ProductSelectionStore) => state.selectedOfferId;
export const selectSelectedAnalogDrug = (state: ProductSelectionStore) => state.selectedAnalogDrug;
export const selectExactDrugSearchId = (state: ProductSelectionStore) => state.exactDrugSearchId;

export const selectIsUsersFeedbackDialogVisible = (state: ProductSelectionStore) => state.isUsersFeedbackDialogVisible;

// actions
export const selectSearchStoreActions = (state: ProductSelectionStore) => state.actions;

// hooks
export const useProductSelectionStoreActions = () => useProductSelectionStore(selectSearchStoreActions, shallow);
export const useSelectedDrugId = () => useProductSelectionStore(selectSelectedDrugId);
export const useSelectedOfferId = () => useProductSelectionStore(selectSelectedOfferId);
export const useSearchQuery = () => useProductSelectionStore(selectSearchQuery);
export const useSearchStoreExactDrugSearchId = () => useProductSelectionStore(selectExactDrugSearchId);
export const useIsExactDrugSearchIdFromAutoSelection = () =>
  useProductSelectionStore(store => store.isExactDrugSearchIdFromAutoSelection);

export const useResetSearchStoreOnUnmount = (omitKeys?: (keyof State)[]) =>
  useUnmount(() => useProductSelectionStore.setState(omitKeys ? omit(defaultState, omitKeys) : defaultState));
