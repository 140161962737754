import { useMemo } from 'react';
import type { To } from 'react-router-dom';
import { resolvePath, useMatches } from 'react-router-dom';

// ----------------------------------------------------------------------

export const useMatchLinks = (...links: Array<To>) => {
  const paths = useMemo(() => links.map(link => resolvePath(link)), [links]);
  const matches = useMatches();

  return matches.filter(match => paths.some(path => match.pathname === path.pathname));
};
