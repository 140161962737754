import { ManageSearch as ManageSearchIcon, Settings as SettingsIcon } from '@mui/icons-material';

import { DorimPromoPaths, OrdersLinks, ProductSelectionPaths, SettingsPaths } from '@/shared/lib/react-router';

import { ReactComponent as CashbackSVG } from './assets/icons/cashback.svg';
import { ReactComponent as OrderApproveSVG } from './assets/icons/order_approve.svg';
import { NavbarBase } from './NavbarBase';
import { NavbarItem } from './NavbarItem';

export const Navbar = () => (
  <NavbarBase>
    <NavbarItem iconComponent={ManageSearchIcon} to={ProductSelectionPaths.ROOT_PATH} title="Подбор предложений" />
    <NavbarItem iconComponent={OrderApproveSVG} to={OrdersLinks.root} title="Заказы" />
    <NavbarItem iconComponent={CashbackSVG} to={DorimPromoPaths.ROOT_PATH} title="Dorim.Promo" />
    <NavbarItem
      iconComponent={SettingsIcon}
      to={SettingsPaths.ROOT}
      title="Настройки"
      submenu={[
        {
          to: SettingsPaths.DISTRIBUTORS,
          title: 'Дистрибьюторы',
        },
        {
          to: SettingsPaths.EXPIRY_DATES,
          title: 'Срок годности в поиске',
        },
        {
          to: SettingsPaths.USERS,
          title: 'Пользователи',
        },
      ]}
    />
  </NavbarBase>
);
