import type { ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, FormControl, Stack } from '@mui/material';

import type { SignInFormData } from '@/api/domains/auth.api';
import { useGlobalErrorStore } from '@/components/dialogs';
import {
  CustomFormLabel,
  RHFCheckbox,
  RHFMaskedPhoneTextField,
  RHFPasswordTextField,
} from '@/components/form-controls';
import { StyledFormProvider, StyledFormRoot, StyledFormTitle } from '@/pages/auth/components/styles';
import { LANDING_PAGE_LINK } from '@/shared/constants/dorim.constants';
import { useAuthMethods, useSessionTokensActions, useSessionTokensShouldStaySignedIn } from '@/shared/lib/auth';
import { AuthPaths } from '@/shared/lib/react-router';
import { Yup } from '@/shared/lib/yup';

import { useSignInStoreActions } from './signIn.store';
import { StyledFormContainer, StyledLandingLink, StyledRestorePasswordLink } from './styles';

// ----------------------------------------------------------------------

type FormValues = SignInFormData & {
  shouldStaySignedIn: boolean;
};

// ----------------------------------------------------------------------

const signInSchema = Yup.object().shape({
  phone: Yup.string().isPossiblePhoneNumber().required('Обязательное поле'),
  password: Yup.string().trim().required('Обязательное поле'),
  shouldStaySignedIn: Yup.boolean(),
});

// ----------------------------------------------------------------------

export const SignInForm = () => {
  const { setPhone } = useSignInStoreActions();
  const { setShouldStaySignedIn } = useSessionTokensActions();

  const storedStaySignedIn = useSessionTokensShouldStaySignedIn();

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(signInSchema),
    defaultValues: {
      phone: '',
      password: '',
      shouldStaySignedIn: storedStaySignedIn ?? true,
    },
  });

  const {
    formState: { isSubmitting },
  } = methods;

  const { signIn } = useAuthMethods();
  const { setGlobalError } = useGlobalErrorStore();

  const handleSubmit = async (formData: FormValues) => {
    try {
      const { password, phone } = formData;

      setPhone(phone);

      await signIn({ password, phone });
    } catch (error) {
      setGlobalError(error);
    }
  };

  const handleStaySignedInChanged = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setShouldStaySignedIn(checked);
  };

  return (
    <StyledFormRoot>
      <StyledFormContainer>
        <StyledFormTitle>Авторизация</StyledFormTitle>

        <StyledFormProvider methods={methods} onSubmit={handleSubmit}>
          <Stack spacing={3} mb={3}>
            <FormControl fullWidth>
              <CustomFormLabel htmlFor="phone">Телефон</CustomFormLabel>
              <RHFMaskedPhoneTextField
                fullWidth
                name="phone"
                id="phone"
                autoComplete="phone"
                variant="filled"
                inputProps={{ 'data-testId': 'signin-phone-input' }}
              />
            </FormControl>

            <FormControl fullWidth>
              <CustomFormLabel htmlFor="password">Пароль</CustomFormLabel>
              <RHFPasswordTextField
                fullWidth
                name="password"
                id="password"
                autoComplete="password"
                variant="filled"
                inputProps={{ 'data-testId': 'signin-password-input' }}
              />
            </FormControl>
          </Stack>

          <FormControl>
            <RHFCheckbox
              name="shouldStaySignedIn"
              label="Оставаться в системе"
              sx={{ marginBottom: 5 }}
              CheckboxProps={{
                color: 'secondary',
                onChange: handleStaySignedInChanged,
                checked: storedStaySignedIn,
              }}
            />
          </FormControl>

          <Stack spacing={2} justifyContent="center" alignItems="center">
            <LoadingButton
              fullWidth
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="secondary"
              data-testId="signin-btn"
            >
              Войти
            </LoadingButton>

            <StyledRestorePasswordLink to={AuthPaths.RESET_PASSWORD.ROOT}>
              <Button fullWidth variant="text" color="secondary" data-testId="signin-reset-password-btn">
                Восстановить пароль
              </Button>
            </StyledRestorePasswordLink>
          </Stack>
        </StyledFormProvider>
      </StyledFormContainer>

      <StyledLandingLink to={LANDING_PAGE_LINK}>
        <Button variant="outlined" color="secondary" data-testId="signin-to-main-page-btn">
          На главную
        </Button>
      </StyledLandingLink>
    </StyledFormRoot>
  );
};
