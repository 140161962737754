import type { To } from 'react-router-dom';

import { useMatchLinks } from './useMatchLinks';

// ----------------------------------------------------------------------

export const useActiveRoutes = (...links: Array<To>) => {
  const matches = useMatchLinks(...links);

  return matches.length > 0;
};
