import { Card, styled } from '@mui/material';

const StyledRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  backgroundColor: theme.palette.background.neutral,
}));

const StyledHeaderTabs = styled(Card)(({ theme }) => ({
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderTop: 'none',
  padding: theme.spacing(0, 2),
})) as typeof Card;

export type TabPanelProps = React.PropsWithChildren<{}>;

export const HeaderTabs = (props: TabPanelProps) => {
  return (
    <StyledRoot>
      <StyledHeaderTabs variant="outlined" {...props} />
    </StyledRoot>
  );
};
