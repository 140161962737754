import { Box, Card, styled } from '@mui/material';

export const StyledContentRoot = styled(Card)({
  flexGrow: 1,
  flexShrink: 0,
  borderTop: 'none',
  overflow: 'visible',
  display: 'flex',
  flexDirection: 'column',
}) as typeof Card;

export const StyledContentBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3, 3),
}));
