import { useUnmount } from 'react-use';
import { devtools } from 'zustand/middleware';

import type { Order } from '@/api/domains/orders.types';
import { createStoreWithReset } from '@/shared/lib/zustand';
import type { OrderDataGridRowData } from '@/shared/types/orders.types';

type DispatchDialogStore = {
  isRedispatchControlDialogOpen: boolean;
  isDispatchDialogOpen: boolean;
  isDispatchWarningDialogOpen: boolean;

  ordersToDispatch: Array<OrderDataGridRowData>;
  ordersToDispatchGuids: Array<Order['guid']>;
  distributorsIdsToDispatch: Array<Order['distributor_id']>;

  actions: {
    openRedispatchControlDialog: () => void;
    closeRedispatchControlDialog: () => void;

    openDispatchDialog: () => void;
    closeDispatchDialog: () => void;

    openDispatchWarningDialog: () => void;
    closeDispatchWarningDialog: () => void;

    setDistributorsIdsToDispatch: (distributorsIdsToDispatch: Array<Order['distributor_id']>) => void;
    addOrdersToDispatchGuids: (ordersGuids: Array<Order['guid']>) => void;
    setOrdersToDispatchGuids: (ordersGuids: Array<Order['guid']>) => void;
    setOrdersToDispatch: (ordersToDispatch: Array<OrderDataGridRowData>) => void;
  };
};

const [useOrdersDispatchDialogStore, resetOrdersDispatchDialogStore] = createStoreWithReset<DispatchDialogStore>()(
  devtools(
    set => ({
      isRedispatchControlDialogOpen: false,
      isDispatchDialogOpen: false,
      isDispatchWarningDialogOpen: false,

      distributorsIdsToDispatch: [],
      ordersToDispatchGuids: [],
      ordersToDispatch: [],

      actions: {
        openRedispatchControlDialog: () =>
          set({ isRedispatchControlDialogOpen: true }, false, 'openRedispatchControlDialog'),
        closeRedispatchControlDialog: () =>
          set({ isRedispatchControlDialogOpen: false }, false, 'closeRedispatchControlDialog'),

        openDispatchDialog: () => set({ isDispatchDialogOpen: true }, false, 'openDispatchDialog'),
        closeDispatchDialog: () => set({ isDispatchDialogOpen: false }, false, 'closeDispatchDialog'),

        openDispatchWarningDialog: () => set({ isDispatchWarningDialogOpen: true }, false, 'openDispatchWarningDialog'),
        closeDispatchWarningDialog: () =>
          set({ isDispatchWarningDialogOpen: false }, false, 'closeDispatchWarningDialog'),

        setDistributorsIdsToDispatch: distributorsIdsToDispatch =>
          set({ distributorsIdsToDispatch }, false, 'setDistributorsIdsToDispatch'),
        setOrdersToDispatch: ordersToDispatch => set({ ordersToDispatch }, false, 'setOrdersToDispatch'),
        addOrdersToDispatchGuids: ordersToDispatchGuids =>
          set(
            state => ({
              ordersToDispatchGuids: [...state.ordersToDispatchGuids, ...ordersToDispatchGuids],
            }),
            false,
            'addOrdersToDispatchGuids',
          ),
        setOrdersToDispatchGuids: ordersToDispatchGuids => set({ ordersToDispatchGuids }, false, 'setOrdersGuids'),
      },
    }),
    {
      name: 'dispatch-dialog-store',
      enabled: import.meta.env.DEV,
    },
  ),
);

export const useOrdersDispatchDialogStoreIsContextWindowsOpen = () =>
  useOrdersDispatchDialogStore(state => ({
    isRedispatchControlDialogOpen: state.isRedispatchControlDialogOpen,
    isDispatchDialogOpen: state.isDispatchDialogOpen,
    isDispatchWarningDialogOpen: state.isDispatchWarningDialogOpen,
  }));

export const useOrdersDispatchDialogStoreActions = () => useOrdersDispatchDialogStore(state => state.actions);

export const useOrdersDispatchStoreOrdersToDispatch = () =>
  useOrdersDispatchDialogStore(state => state.ordersToDispatch);

export const useOrdersDispatchStoreDistributorsIdsToDispatch = () =>
  useOrdersDispatchDialogStore(state => state.distributorsIdsToDispatch);

export const useOrdersToDispatchGuids = () => useOrdersDispatchDialogStore(state => state.ordersToDispatchGuids);
export const useOrdersDispatchDialogStoreOnUnmount = () => useUnmount(resetOrdersDispatchDialogStore);
