import type { ExtractPathParams } from '../lib';
import { generatePath, joinPath } from '../lib';

const ROOT_PATH = '/dorim-promo';

// ----------------------------------------------------------------------

const SPECIFICATIONS_ROOT_PATH = joinPath(ROOT_PATH, 'specifications');
const SPECIFICATIONS_NOTICE_PATH = joinPath(SPECIFICATIONS_ROOT_PATH, 'accept/:specificationId/notice');
export type SpecificationsNoticePathParams = ExtractPathParams<typeof SPECIFICATION_DETAIL_PATH>;

const MAIN_CONTRACT_ROOT_PATH = joinPath(SPECIFICATIONS_ROOT_PATH, 'main-contract');
const MAIN_CONTRACT_DOCUMENT_PATH = joinPath(MAIN_CONTRACT_ROOT_PATH, 'document');
const MAIN_CONTRACT_SIGNATURE_PROTOCOL_PATH = joinPath(MAIN_CONTRACT_ROOT_PATH, 'signature-protocol');

const SPECIFICATION_DETAIL_PATH = joinPath(SPECIFICATIONS_ROOT_PATH, 'detail/:specificationId');
export type SpecificationDetailPathParams = ExtractPathParams<typeof SPECIFICATION_DETAIL_PATH>;

const SPECIFICATION_DETAIL_PROCUREMENT_PLAN = joinPath(SPECIFICATION_DETAIL_PATH, 'procurement-plan');
const SPECIFICATION_DETAIL_PLANOGRAM_PATH = joinPath(SPECIFICATION_DETAIL_PATH, 'planogram');

const SPECIFICATION_DETAIL_MAIN_CONTRACT_ROOT_PATH = joinPath(SPECIFICATION_DETAIL_PATH, 'main-contract');
const SPECIFICATION_DETAIL_MAIN_CONTRACT_NOTICE_PATH = joinPath(SPECIFICATION_DETAIL_MAIN_CONTRACT_ROOT_PATH, 'notice');
const SPECIFICATION_DETAIL_MAIN_CONTRACT_DETAIL_PATH = joinPath(SPECIFICATION_DETAIL_MAIN_CONTRACT_ROOT_PATH, 'detail');
const SPECIFICATION_DETAIL_MAIN_CONTRACT_DOCUMENT_PATH = joinPath(
  SPECIFICATION_DETAIL_MAIN_CONTRACT_DETAIL_PATH,
  'document',
);
const SPECIFICATION_DETAIL_MAIN_CONTRACT_SIGNATURE_PROTOCOL_PATH = joinPath(
  SPECIFICATION_DETAIL_MAIN_CONTRACT_DETAIL_PATH,
  'signature-protocol',
);

// ----------------------------------------------------------------------

const SPECIFICATION_DETAIL_CONTRACT_ROOT_PATH = joinPath(SPECIFICATION_DETAIL_PATH, 'contract');
const SPECIFICATION_DETAIL_CONTRACT_NOTICE_PATH = joinPath(SPECIFICATION_DETAIL_CONTRACT_ROOT_PATH, 'notice');
const SPECIFICATION_DETAIL_CONTRACT_DETAIL_PATH = joinPath(SPECIFICATION_DETAIL_CONTRACT_ROOT_PATH, 'detail');
const SPECIFICATION_DETAIL_CONTRACT_DOCUMENT_PATH = joinPath(SPECIFICATION_DETAIL_CONTRACT_DETAIL_PATH, 'document');
const SPECIFICATION_DETAIL_CONTRACT_SIGNATURE_PROTOCOL_PATH = joinPath(
  SPECIFICATION_DETAIL_CONTRACT_DETAIL_PATH,
  'signature-protocol',
);

// ----------------------------------------------------------------------

const MARKETING_ORG_DETAIL_PATH = joinPath(SPECIFICATION_DETAIL_PATH, 'marketing-org/:marketingOrgId');
export type MarketingOrgDetailPathParams = ExtractPathParams<typeof MARKETING_ORG_DETAIL_PATH>;

const ANALYTICS_PATH = joinPath(MARKETING_ORG_DETAIL_PATH, 'analytics/:drugId');
export type AnalyticsPathParams = ExtractPathParams<typeof ANALYTICS_PATH>;

// ----------------------------------------------------------------------

export const DorimPromoPaths = {
  ROOT_PATH,

  SPECIFICATIONS_ROOT_PATH,
  SPECIFICATIONS_NOTICE_PATH,
  generateSpecificationsNoticePath: (params: SpecificationDetailPathParams) =>
    generatePath(SPECIFICATIONS_NOTICE_PATH, params),

  MAIN_CONTRACT_ROOT_PATH,
  MAIN_CONTRACT_DOCUMENT_PATH,
  MAIN_CONTRACT_SIGNATURE_PROTOCOL_PATH,

  SPECIFICATION_DETAIL_PATH,
  generateSpecificationDetailPath: (params: SpecificationDetailPathParams) =>
    generatePath(SPECIFICATION_DETAIL_PATH, params),

  SPECIFICATION_DETAIL_PROCUREMENT_PLAN,
  generateSpecificationDetailProcurementPlanPath: (params: SpecificationDetailPathParams) =>
    generatePath(SPECIFICATION_DETAIL_PROCUREMENT_PLAN, params),
  SPECIFICATION_DETAIL_PLANOGRAM_PATH,
  generateSpecificationDetailPlanogramPath: (params: SpecificationDetailPathParams) =>
    generatePath(SPECIFICATION_DETAIL_PLANOGRAM_PATH, params),

  SPECIFICATION_DETAIL_MAIN_CONTRACT_ROOT_PATH,
  SPECIFICATION_DETAIL_MAIN_CONTRACT_NOTICE_PATH,
  generateSpecificationDetailMainContractNoticePath: (params: SpecificationDetailPathParams) =>
    generatePath(SPECIFICATION_DETAIL_MAIN_CONTRACT_NOTICE_PATH, params),

  SPECIFICATION_DETAIL_MAIN_CONTRACT_DETAIL_PATH,
  SPECIFICATION_DETAIL_MAIN_CONTRACT_DOCUMENT_PATH,
  generateSpecificationDetailMainContractDocumentPath: (params: SpecificationDetailPathParams) =>
    generatePath(SPECIFICATION_DETAIL_MAIN_CONTRACT_DOCUMENT_PATH, params),

  SPECIFICATION_DETAIL_MAIN_CONTRACT_SIGNATURE_PROTOCOL_PATH,
  generateSpecificationDetailMainContractSignatureDocumentPath: (params: SpecificationDetailPathParams) =>
    generatePath(SPECIFICATION_DETAIL_MAIN_CONTRACT_SIGNATURE_PROTOCOL_PATH, params),

  SPECIFICATION_DETAIL_CONTRACT_ROOT_PATH,
  SPECIFICATION_DETAIL_CONTRACT_NOTICE_PATH,
  generateSpecificationDetailContractNoticePath: (params: SpecificationDetailPathParams) =>
    generatePath(SPECIFICATION_DETAIL_CONTRACT_NOTICE_PATH, params),
  SPECIFICATION_DETAIL_CONTRACT_DETAIL_PATH,
  SPECIFICATION_DETAIL_CONTRACT_DOCUMENT_PATH,
  generateSpecificationDetailContractDocumentPath: (params: SpecificationDetailPathParams) =>
    generatePath(SPECIFICATION_DETAIL_CONTRACT_DOCUMENT_PATH, params),
  SPECIFICATION_DETAIL_CONTRACT_SIGNATURE_PROTOCOL_PATH,
  generateSpecificationDetailContractSignatureProtocolPath: (params: SpecificationDetailPathParams) =>
    generatePath(SPECIFICATION_DETAIL_CONTRACT_SIGNATURE_PROTOCOL_PATH, params),

  MARKETING_ORG_DETAIL_PATH,
  generateMarketingOrgDetailPath: (params: MarketingOrgDetailPathParams) =>
    generatePath(MARKETING_ORG_DETAIL_PATH, params),

  ANALYTICS_PATH,
  generateAnalyticsPath: (params: AnalyticsPathParams) => generatePath(ANALYTICS_PATH, params),
} as const;
