import parseHtml from 'html-react-parser';
import { format } from 'date-fns';
import type { CountryCode } from 'libphonenumber-js';
import { parsePhoneNumber } from 'libphonenumber-js';

import type { Address } from '@/api/types/address.types';

import { DEFAULT_DATE_TIME_FORMAT } from '../constants/date-formats.constants';

// ----------------------------------------------------------------------

export const formatDrugNumber = (value: number) => (value > 0 ? `№${value}` : '');

// ----------------------------------------------------------------------

export const formatDrugName = (name: string, form: string, number: number) =>
  `${name} ${form} ${formatDrugNumber(number)}`;

export const formatDrugFullName = (drug: { name: string; form: string; number: number }) =>
  formatDrugName(drug.name, drug.form, drug.number);

// ----------------------------------------------------------------------
//
export const formatMakerName = (name: string, countryName?: string) =>
  countryName ? `${name} (${countryName})` : name;

// ----------------------------------------------------------------------

export const formatDateTime = (date: string | number | Date, dateFormat: string = DEFAULT_DATE_TIME_FORMAT) => {
  try {
    return format(new Date(date), dateFormat);
  } catch {
    return String(date);
  }
};

// ----------------------------------------------------------------------

export const formatNumberByThousands = (value: string | number) => new Intl.NumberFormat('ru-RU').format(Number(value));

// ----------------------------------------------------------------------

const HIGHLIGHT_COLOR = 'yellow';

export const getSearchPattern = (keyword: string) => {
  const regExpString = keyword
    .trim()
    .replace(/[^а-яА-ЯёЁa-zA-Z0-9]+/g, ').*?(')
    .replace(/[её]/gi, '(?:е|ё)');

  return new RegExp(`(${regExpString})`, 'i');
};

export const highlightKeyword = (source: string, keyword: string, highlightColor: string = HIGHLIGHT_COLOR) => {
  const preparedSource = source.replace(
    getSearchPattern(keyword),
    `<span style="background-color: ${highlightColor}">$&</span>`,
  );

  return parseHtml(preparedSource);
};

// ----------------------------------------------------------------------

/**
 * Provides correct endings to words coming after numbers.
 */
export const formatWordEnding = (number: number, titles: [string, string, string]) => {
  if (number % 10 === 1 && number % 100 !== 11) {
    return titles[0];
  }

  if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
    return titles[1];
  }

  return titles[2];
};

// ----------------------------------------------------------------------

const addBracketsToUZPhone = (phoneNumber: string) => {
  const [countyCode, toBracket, ...rest] = phoneNumber.split(' ');
  if (!toBracket) {
    return phoneNumber;
  }

  const withBrackets = `(${toBracket.slice(0, 2)})${toBracket.slice(2)}`;

  return [countyCode, withBrackets, ...rest].join(' ');
};

export const formatPhoneNumber = (phoneNumber: string, countryCode: CountryCode = 'UZ') => {
  const phoneNumberInfo = parsePhoneNumber(phoneNumber, countryCode);
  const internationalFormatPhone = phoneNumberInfo.formatInternational();

  if (phoneNumberInfo?.country === 'UZ') {
    return addBracketsToUZPhone(internationalFormatPhone);
  }

  return internationalFormatPhone;
};

type PrepareAddressOptions = {
  separator?: string;
};

export const prepareAddress = (address: Address, options?: PrepareAddressOptions) => {
  const { region, city, street_type, street, building, house, apartment } = address;
  const { separator = ', ' } = options ?? {};

  const regionPart = region ? `${region.name}` : null;

  const settlementTypePart = city && city.settlement_type ? `${city.settlement_type.name}` : null;
  const cityPart = city ? `${settlementTypePart} ${city.full_name}`.trim() : null;

  const streetTypePart = street_type ? `${street_type.name}` : 'ул.';
  const streetPart = street ? `${streetTypePart} ${street}`.trim() : null;

  const housePart = house ? `д. ${house}` : null;
  const buildingPart = building ? `корп. ${building}` : null;
  const apartmentPart = apartment ? `кв. ${apartment}` : null;

  const fullAddress = [streetPart, housePart, buildingPart, apartmentPart].filter(Boolean).join(separator);
  const completeAddress = [regionPart, cityPart, fullAddress].filter(Boolean).join(separator);
  const cityAddress = [cityPart, fullAddress].filter(Boolean).join(separator);

  return {
    fullAddress,
    completeAddress,
    cityAddress,
    region: regionPart,
    city: cityPart,
    street: streetPart,
    house: housePart,
    building: buildingPart,
    apartment: apartmentPart,
  };
};
