import { useParams } from 'react-router-dom';

import { RoundedButton } from '@/components/buttons';
import { useMainContractDocument } from '@/shared/hooks/useMainContractDocument';
import type { SpecificationDetailPathParams } from '@/shared/lib/react-router';

import { useSpecificationStoreActions } from '../specification.store';
import { useConfirmReceivingBonus } from './useConfirmReceivingBonus';

export const BonusConfirmationButton = () => {
  const { isSigned } = useMainContractDocument();
  const { openBonusConfirmationDialog, openMainContractConfirmationDialog } = useSpecificationStoreActions();

  const openDialog = isSigned ? openBonusConfirmationDialog : openMainContractConfirmationDialog;

  return (
    <RoundedButton variant="contained" onClick={openDialog}>
      Получить выплату
    </RoundedButton>
  );
};

export const AcceptPaymentButton = () => {
  const params = useParams<SpecificationDetailPathParams>();
  const specificationId = Number(params.specificationId);

  const { isLoading, mutate: confirmReceivingCashbackBonus } = useConfirmReceivingBonus();
  const { openContractClosingDialog } = useSpecificationStoreActions();

  return (
    <RoundedButton
      loading={isLoading}
      variant="contained"
      onClick={() => {
        confirmReceivingCashbackBonus(specificationId, {
          onSuccess: openContractClosingDialog,
        });
      }}
    >
      Подтвердить получение выплаты
    </RoundedButton>
  );
};

export const CalculationErrorReportButton = () => {
  const { openCalculationErrorReportDialog } = useSpecificationStoreActions();

  return (
    <RoundedButton variant="outlined" onClick={openCalculationErrorReportDialog}>
      Сообщить об ошибке в подсчете
    </RoundedButton>
  );
};
