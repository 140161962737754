import { useSpecification } from '@/shared/hooks/useSpecification';
import {
  BreadcrumbLink,
  DorimPromoPaths,
  LazyBreadcrumb,
  type RouteObjectWithBreadcrumbs,
  type SpecificationDetailPathParams,
} from '@/shared/lib/react-router';

import { SpecificationContractPageRoute } from './contract';
import { SpecificationDetailMainContractPageRoute } from './main-contract';
import { MarketingOrgDetailPageRoute } from './marketing-org-detail';
import { SpecificationPageRoute } from './specification';

export const SpecificationDetailPageRoute: RouteObjectWithBreadcrumbs = {
  path: DorimPromoPaths.SPECIFICATION_DETAIL_PATH,
  handle: {
    breadcrumb: (params: SpecificationDetailPathParams) => ({
      Component: () => {
        const { data, isLoading } = useSpecification({
          specificationId: Number(params.specificationId),
        });

        return (
          <LazyBreadcrumb isLoading={isLoading}>
            <BreadcrumbLink to={DorimPromoPaths.generateSpecificationDetailPath(params)}>
              {data?.agreement?.document_number || 'Спецификация'}
            </BreadcrumbLink>
          </LazyBreadcrumb>
        );
      },
    }),
  },
  children: [
    SpecificationPageRoute,
    MarketingOrgDetailPageRoute,
    SpecificationDetailMainContractPageRoute,
    SpecificationContractPageRoute,
  ],
};
