import { Stack, Typography } from '@mui/material';

import { formatNumberByThousands } from '@/shared/helpers/format.helpers';

type ContentTotalAmountProps = {
  paymentSum: number;
};

export const ContentTotalAmount = (props: ContentTotalAmountProps) => (
  <Typography component={Stack} variant="Display/Bold/small" direction="row" spacing={1}>
    <Typography component="span" variant="inherit">
      {formatNumberByThousands(props.paymentSum)}
    </Typography>
    <Typography component="span" variant="inherit" fontWeight={400}>
      UZS
    </Typography>
  </Typography>
);
