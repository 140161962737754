import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { UserContractorApi } from '@/api/domains/user-contractor.api';
import { UserContractorQueryKeys } from '@/api/domains/user-contractor.query-keys';
import type { UserContractor } from '@/api/domains/user-contractor.type';

type UseUserContractorOptions = Omit<
  UseQueryOptions<UserContractor, unknown, UserContractor, Array<string>>,
  'queryKey' | 'queryFn'
>;

export const useUserContractor = (options?: UseUserContractorOptions) =>
  useQuery({
    queryKey: UserContractorQueryKeys.contractor,
    queryFn: UserContractorApi.fetchContractor,
    staleTime: Infinity,
    ...options,
  });
