import { forwardRef, useEffect, useRef, useState } from 'react';
import { Slider as MUISlider, styled, useTheme, type SliderProps as MUISliderProps } from '@mui/material';

import { formatNumberByThousands } from '@/shared/helpers/format.helpers';
import { useMergeRefs } from '@/shared/lib/react';

import { calculateValuePosition } from './helpers';

export type SliderProps = MUISliderProps & {
  currentValue: number;
  values: number[];
  isShowExceedingValue?: boolean;
  sliderColor?: string;
};

const StyledRoot = styled('div')({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'flex-start',
  height: 70,
  paddingTop: 25,
  paddingBottom: 64,
});

type StyledSliderProps = {
  sliderColor?: string;
  valueLabelOffset?: number;
};
const StyledSlider = styled(MUISlider, {
  shouldForwardProp: prop => prop !== 'sliderColor' && prop !== 'valueLabelOffset',
})<StyledSliderProps>(({ theme, sliderColor, valueLabelOffset }) => ({
  flexGrow: 1,
  flexShrink: 1,

  '& .MuiSlider-valueLabel': {
    background: 'unset',
    ...theme.typography['Title/Bold/large'],
    color: theme.palette.neutral[900],
    top: 0,
    padding: 0,
    left: `${valueLabelOffset}px`,
  },
  '.MuiSlider-thumb': {
    backgroundColor: sliderColor,
    height: '14px',
    width: '14px',

    border: '2px solid #fff',
  },
  '.MuiSlider-track': {
    height: '8px',
    backgroundColor: sliderColor,
    border: 'none',
  },
  '.MuiSlider-rail': {
    backgroundColor: theme.palette.neutral[300],
    height: '8px',
  },
  '.MuiSlider-mark': {
    display: 'none',
  },
  '.MuiSlider-markLabel': {
    top: '20px',
    transform: 'translateX(-100%)',

    '&[data-index="0"]': {
      transform: 'translateX(0)',
    },
  },
}));

export const Slider = forwardRef<HTMLSpanElement, SliderProps>((props, ref) => {
  const {
    values,
    currentValue,
    isShowExceedingValue,
    sliderColor,
    value: passedValue,
    max: passedMax,
    ...sliderProps
  } = props;

  const theme = useTheme();
  const currentSliderColor = sliderColor ?? theme.palette.tetriary.main;

  const lastValue = values[values.length - 1];
  const valueLabel =
    currentValue > lastValue && !isShowExceedingValue
      ? `${formatNumberByThousands(lastValue)}+`
      : formatNumberByThousands(currentValue);

  const sliderRef = useRef<HTMLSpanElement | null>(null);
  const setSliderRef = useMergeRefs<HTMLSpanElement>(sliderRef, ref);
  const [valueLabelOffset, setValueLabelOffset] = useState(0);

  useEffect(() => {
    if (sliderRef.current) {
      const valueLabel: HTMLElement | null = sliderRef.current.querySelector('.MuiSlider-valueLabel');
      const thumb: HTMLElement | null = sliderRef.current.querySelector('.MuiSlider-thumb');

      if (valueLabel && thumb) {
        const sliderRect = sliderRef.current.getBoundingClientRect();
        const thumbRect = thumb.getBoundingClientRect();
        const valueLabelRect = valueLabel.getBoundingClientRect();

        const centerLabelX = valueLabelRect.width / 2;
        const rightLabelOffset = Math.min(0, sliderRect.right - valueLabelRect.right + centerLabelX);
        const thumbCenterX = thumbRect.left + thumbRect.width / 2 - sliderRect.left;

        let leftOffset = centerLabelX * -1;

        if (rightLabelOffset !== 0) {
          leftOffset = rightLabelOffset - centerLabelX;
        } else if (thumbCenterX < centerLabelX) {
          leftOffset = -thumbCenterX;
        }

        setValueLabelOffset(leftOffset);
      }
    }
  }, [valueLabel]);

  return (
    <StyledRoot>
      <StyledSlider
        ref={setSliderRef}
        value={passedValue ? passedValue : calculateValuePosition(currentValue, values)}
        max={passedMax ? passedMax : values.length - 1}
        valueLabelFormat={valueLabel}
        size="medium"
        valueLabelDisplay="on"
        sliderColor={currentSliderColor}
        valueLabelOffset={valueLabelOffset}
        {...sliderProps}
      />
    </StyledRoot>
  );
});
