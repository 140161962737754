import { useState } from 'react';
import type { TooltipProps } from '@mui/material';
import { Divider, Stack, Tooltip, Typography } from '@mui/material';

type DorimPromoChartLabelProps = React.PropsWithChildren & {
  isFirst: boolean;
  isLast?: boolean;
  isPlanExceeded?: boolean;
  tooltipTitle?: TooltipProps['title'];
};

export const DorimPromoChartLabel = (props: DorimPromoChartLabelProps) => {
  const { isFirst, isLast, isPlanExceeded, tooltipTitle, children } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLHRElement | null>(null);

  return (
    <Tooltip
      arrow
      placement="bottom"
      title={tooltipTitle}
      slotProps={{
        popper: {
          disablePortal: true,
          anchorEl,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -50],
              },
            },
          ],
        },
      }}
    >
      <Stack
        direction={isFirst ? 'row-reverse' : 'row'}
        spacing={0.5}
        alignItems="center"
        mr={isLast && isPlanExceeded ? 7 : 0}
      >
        <Typography variant="Body/medium" lineHeight={20 / 14} textAlign="right" component={Stack} pt={1}>
          {children}
        </Typography>
        <Divider
          ref={setAnchorEl}
          flexItem
          variant="dashed"
          orientation="vertical"
          sx={{ borderColor: 'neutral.500' }}
        />
      </Stack>
    </Tooltip>
  );
};
