import { paramsToQueryKey } from '@/shared/lib/react-query';

import type { FetchContractorContactsParams, FetchDistributorInfoParams } from './contractors.api';

const PREFIX = 'contractors';

export const ContractorsQueryKeys = {
  root: [PREFIX],

  getContractorContactsKey: (params: FetchContractorContactsParams) => paramsToQueryKey([PREFIX, 'contacts'], params),
  getDistributorInfoKey: (params: Partial<FetchDistributorInfoParams>) =>
    paramsToQueryKey([PREFIX, 'distributor-info'], params),
} as const;
