import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { InfoApi, type InfoParams } from '@/api/domains/info.api';
import { InfoQueryKeys } from '@/api/domains/info.query-keys';
import type { Info } from '@/api/domains/info.types';

// ----------------------------------------------------------------------

const defaultItems: Info[] = [];

export const useInfo = (params?: InfoParams, options?: Pick<UseQueryOptions, 'enabled'>) => {
  const infoQuery = useQuery({
    queryKey: InfoQueryKeys.info(params),
    queryFn: () => InfoApi.fetchInfo(params),
    keepPreviousData: true,
    staleTime: Infinity,
    ...options,
  });

  const { isLoading, data } = infoQuery;

  const info = data?.items ?? defaultItems;

  return { infoQuery, isLoading, info };
};
