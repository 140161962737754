import { Outlet, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';

import { Header } from '@/layouts/Header';
import { PageContainer, PageContent } from '@/layouts/Page';
import { useSpecification } from '@/shared/hooks/useSpecification';
import type { SpecificationDetailPathParams } from '@/shared/lib/react-router';

import {
  AcceptContractConfirmationDialog,
  BonusConfirmationDialog,
  CalculationErrorReportDialog,
  ContractClosingDialog,
  MainContractConfirmationDialog,
} from '../dialogs';
import { ContentTotalAmountFloatingPanel } from '../shared';
import { SpecificationActionBar } from '../SpecificationActionBar';
import { SpecificationDocumentToolbar } from '../SpecificationDocumentToolbar';

export const Layout = () => {
  const { specificationId } = useParams<SpecificationDetailPathParams>();

  const { data: specification } = useSpecification({
    specificationId: Number(specificationId),
  });

  return (
    <PageContainer title="Dorim.Promo • Спецификация">
      <Header hasBreadcrumbs />

      <PageContent>
        <Stack spacing={1} height="100%">
          <SpecificationDocumentToolbar />
          <Outlet />
          {specification?.agreement && <ContentTotalAmountFloatingPanel agreement={specification.agreement} />}
        </Stack>
      </PageContent>

      <SpecificationActionBar />

      <CalculationErrorReportDialog />
      <BonusConfirmationDialog />
      <ContractClosingDialog />
      <MainContractConfirmationDialog />
      <AcceptContractConfirmationDialog />
    </PageContainer>
  );
};
