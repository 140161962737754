import { useState } from 'react';

import { rulesConfig } from '@/modules/account/PasswordStrengthValidation/config';
import type { ValidationRuleStatus } from '@/modules/account/PasswordStrengthValidation/types';

const initialState = rulesConfig.map((): ValidationRuleStatus => 'idle');

export const usePasswordStrengthValidation = () => {
  const [rulesStatuses, setRulesStatuses] = useState(initialState);

  const runPasswordStrengthValidation = (password: string) => {
    if (password === '') {
      setRulesStatuses(initialState);

      return { isPasswordStrong: false };
    }

    const updatedRulesStatuses: Array<ValidationRuleStatus> = [];
    let successfulRulesCount = 0;

    rulesConfig.forEach((rule, index) => {
      if (rule.test(password)) {
        updatedRulesStatuses[index] = 'success';
        successfulRulesCount++;
      } else {
        updatedRulesStatuses[index] = 'error';
      }
    });

    setRulesStatuses(updatedRulesStatuses);

    return { isPasswordStrong: successfulRulesCount === rulesStatuses.length };
  };

  return {
    validationResult: rulesStatuses,
    runPasswordStrengthValidation,
  };
};
