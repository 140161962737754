import { Controller, useFormContext } from 'react-hook-form';
import type { NumericFormatProps, OnValueChange } from 'react-number-format';
import { NumericFormat } from 'react-number-format';
import type { TextFieldProps } from '@mui/material';
import { TextField } from '@mui/material';

import { isSafeNumeric } from '@/shared/helpers/strings.helpers';

// ----------------------------------------------------------------------

type Props = Omit<
  NumericFormatProps,
  'customInput' | 'onValueChange' | 'size' | 'color' | 'valueIsNumericString' | 'allowNegative'
> &
  Omit<TextFieldProps, 'name' | 'label' | 'error' | 'helperText'> & {
    name: string;
    label?: string;
  };

// ----------------------------------------------------------------------

export const RHFIntegerTextField = (props: Props) => {
  const { name, ...textFieldProps } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ref, ...fieldProps }, fieldState: { error } }) => {
        const handleValueChange: OnValueChange = ({ value }) => {
          onChange(value);
        };

        return (
          <NumericFormat
            isAllowed={({ value }) => isSafeNumeric(value)}
            getInputRef={ref}
            customInput={TextField}
            error={!!error}
            helperText={error?.message ?? ''}
            valueIsNumericString
            allowNegative={false}
            thousandSeparator=" "
            decimalScale={0}
            {...fieldProps}
            {...textFieldProps}
            onValueChange={handleValueChange}
          />
        );
      }}
    />
  );
};
