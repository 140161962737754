import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { DorimPromoQueryKeys } from '@/api/domains/dorim-promo.query-keys';
import { ReportApi } from '@/api/domains/report.api';

export const useSendCashbackCalculationErrorReport = () => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ReportApi.sendDorimPromoCalculationErrorReport,
    onSuccess: async () => {
      snackbar.enqueueSnackbar('Ваше обращение зафиксировано', { variant: 'success' });
      await queryClient.invalidateQueries({ queryKey: DorimPromoQueryKeys.root });
    },
  });
};
