export const paramsToQueryKey = <T extends Record<string, any>>(baseKey: Array<unknown>, params?: T) => {
  if (params) {
    for (const key in params) {
      if (params[key] !== null && params[key] !== undefined) {
        baseKey.push({ [key]: params[key].toString() });
      }
    }
  }

  return baseKey;
};
