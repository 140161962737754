import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { Alert, Box, CircularProgress } from '@mui/material';
import { keys, map, pickBy, some } from 'lodash';

import {
  ConfirmationDialogActionCancelButton,
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  useGlobalErrorStore,
} from '@/components/dialogs';
import { FormProvider, RHFCheckbox } from '@/components/form-controls';
import { useInfo } from '@/shared/hooks/useInfo';
import type { SpecificationDetailPathParams } from '@/shared/lib/react-router';

import { useSpecificationStoreActions } from '../../specification.store';
import { useSendCashbackCalculationErrorReport } from '../useSendCashbackCalculationErrorReport';

const OPTION_NAME = 'optionId-';

type FormValues = {
  reportOptions: Record<`${typeof OPTION_NAME}${number}`, boolean>;
};

export const CashbackCalculationErrorReportFrom = () => {
  const {
    infoQuery: { isInitialLoading, refetch: fetchErrorTypes },
    info: errorTypes,
  } = useInfo(
    {
      aliases: ['error_type.cashback'],
    },
    {
      enabled: false,
    },
  );

  const methods = useForm<FormValues>({
    defaultValues: async () => {
      const { data } = await fetchErrorTypes();

      if (!data) {
        return {} as FormValues;
      }

      return {
        reportOptions: data.items.reduce<FormValues['reportOptions']>((acc, errorType) => {
          acc[`${OPTION_NAME}${errorType.id}`] = false;

          return acc;
        }, {}),
      };
    },
  });

  const {
    watch,
    setError,
    clearErrors,
    formState: { isSubmitting, errors },
  } = methods;

  useEffect(() => {
    const subscription = watch(({ reportOptions }) => {
      if (!errors.root) {
        return;
      }

      if (some(reportOptions, Boolean)) {
        clearErrors('root');
      }
    });

    return subscription.unsubscribe;
  }, [errors, clearErrors, watch]);

  const params = useParams<SpecificationDetailPathParams>();
  const sendCashbackReportMutation = useSendCashbackCalculationErrorReport();
  const { closeCalculationErrorReportDialog } = useSpecificationStoreActions();
  const { setGlobalError } = useGlobalErrorStore();

  const handleSubmit = async ({ reportOptions }: FormValues) => {
    try {
      if (!some(reportOptions, Boolean)) {
        setError('root', { message: 'Выберите тип ошибки' });
        return;
      }

      const selectedReportOptions = pickBy(reportOptions, Boolean);
      const preparedErrorTypeIds = map(keys(selectedReportOptions), filedName =>
        Number(filedName.replace(OPTION_NAME, '')),
      );

      await sendCashbackReportMutation.mutateAsync({
        error_type_ids: preparedErrorTypeIds,
        agreement_id: Number(params.specificationId),
      });

      closeCalculationErrorReportDialog();
    } catch (error) {
      setGlobalError(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit} sx={{ width: '100%' }}>
      {errors.root && <Alert severity="error">{errors.root.message}</Alert>}
      {isInitialLoading && (
        <Box display="flex" justifyContent="center">
          <CircularProgress disableShrink color="secondary" />
        </Box>
      )}
      {!isInitialLoading && (
        <Box component="ul">
          {errorTypes.map(errorType => (
            <Box key={errorType.id} component="li" textAlign="left">
              <RHFCheckbox name={`reportOptions.${OPTION_NAME}${String(errorType.id)}`} label={errorType.name} />
            </Box>
          ))}
        </Box>
      )}
      <ConfirmationDialogActions>
        <ConfirmationDialogActionCancelButton onClick={closeCalculationErrorReportDialog} />
        <ConfirmationDialogActionProceedButton type="submit" autoFocus loading={isSubmitting}>
          Отправить
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </FormProvider>
  );
};
