import { Divider, Stack, Typography } from '@mui/material';

import { type CloudCartSummery } from '@/entities/cloud-carts';

import { AnimatedSummaryEntry } from './SummaryBarEntry';

export type SummaryBarContentProps = {
  isLoading: boolean;
  summery: CloudCartSummery;
};

export const SummaryBarContent = (props: SummaryBarContentProps) => {
  const { isLoading, summery } = props;

  return (
    <Stack direction="row" alignItems="center" spacing={4} pr={4}>
      <Typography noWrap variant="Body/Bold/large">
        В корзине
      </Typography>
      <Divider flexItem orientation="vertical" />
      <Stack direction="row" spacing={4} alignItems="center" color={isLoading ? 'neutral.500' : 'text.primary'}>
        <AnimatedSummaryEntry name="Позиций" value={summery.entriesCount} minWidth="64px" />
        <Divider flexItem orientation="vertical" />
        <AnimatedSummaryEntry name="Предоплата" value={summery.totalPrepaymentWithVat} />
        <AnimatedSummaryEntry name="Отсрочка" value={summery.totalInstallmentPaymentWithVat} />
        <AnimatedSummaryEntry
          name="Скидка"
          value={summery.totalDiscountWithVat}
          color={isLoading ? 'neutral.500' : 'custom.green'}
        />
        <AnimatedSummaryEntry
          name="Общая с НДС"
          value={summery.totalPriceWithVat}
          color={isLoading ? 'neutral.500' : 'primary.main'}
        />
      </Stack>
      <Divider flexItem orientation="vertical" />
    </Stack>
  );
};
