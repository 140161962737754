import type { Info } from '@/api/domains/info.types';
import { httpClient } from '@/shared/lib/axios';

import type { PaginatedList, PaginationParams } from '../types/pagination.types';

// ----------------------------------------------------------------------

/**
 * - `contractor_type` - Тип контрагента
 * - `contractor_activity_status` - Статус ведения деятельности контрагента
 * - `contractor_legal_status` - Форма собственности
 * - `address_type` - Тип адреса
 * - `settlement_type` - Тип населенного пункта
 * - `street_type` - Тип улицы
 * - `contact_type` - Тип контакта
 * - `vat` - Ставка НДС
 * - `skip_reason` - Тип причины пропуска позиции
 * - `error_type` - Тип ошибки
 * - `error_type.cashback` - Тип ошибок кешбэка (при неправильном подсчете кешбэка)
 */

export type InfoAlias =
  | 'contractor_type'
  | 'contractor_activity_status'
  | 'contractor_legal_status'
  | 'address_type'
  | 'settlement_type'
  | 'street_type'
  | 'contact_type'
  | 'vat'
  | 'skip_reason'
  | 'error_type'
  | 'error_type.cashback';

export type InfoParams = PaginationParams & {
  aliases?: Array<InfoAlias>;
};

// ----------------------------------------------------------------------

export const InfoApi = {
  fetchInfo: async (params?: InfoParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Info>>('info', { params });

    return data;
  },
};
